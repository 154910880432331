import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { footerTheme } from 'theme';
import useStyles from './styles';

const SkeletonFooter = () => {
  const styles = useStyles();

  return (
    <ThemeProvider theme={footerTheme}>
      <footer className={styles.darkFooter}>
      <Container className={styles.container}>
          <Grid container className={styles.bottomNavContainer}>
            <Grid item xs={12} >
              <Skeleton animation="wave" height={75} style={{
                marginTop: -5,
                marginRight: 10,
                marginLeft: 10,
              }} />
            </Grid>
          </Grid>
      </Container>
      </footer>
    </ThemeProvider>
  );
};

export default SkeletonFooter;
